import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { AppBar,Typography, TextField, Button, IconButton,Box,Checkbox, } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@mui/material/Switch";
import RouteIcon from "@mui/icons-material/Route";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BoltIcon from "@mui/icons-material/Bolt";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaidIcon from "@mui/icons-material/Paid";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { minWidth } from '@mui/system';


/*
:[{"CalledInToday":2416,"RushToday":573,"LastHour":287,"RushLastHour":97,"PoddedBurnsToday":125,"BurnsLastHour":51,"ExceptionActive":16,"Burning":16,"DeliveredToday":1862,
"DeliveredLastHour":348,"DCTinNextHour":120,"DCTRestOfDay":468,"DCTFutureDates":198,"companyID":1}]
*/

const DashboardBar = props => {  
  // Get the initial filter state from localStorage or default to 'ALL'
  const getInitialFilter = () => {
    const savedFilter = localStorage.getItem('dashboardActiveFilter');
    return savedFilter || 'ALL';
  };

  const [localServiceChecked, setLocalServiceChecked] = useState(props.serviceChecked);
  const [localDispatchChecked, setLocalDispatchChecked] = useState(props.dispatchChecked);
  const [localSalesChecked, setLocalSalesChecked] = useState(props.salesChecked);
  const [localDcChecked, setLocalDcChecked] = useState(props.dcChecked);
  const [activeFilter, setActiveFilter] = useState(getInitialFilter());

    const classes = {
        root: {
          backgroundColor: "#010C11",
        },
        appBar: {
          overflow: "auto",
        },
        itemBox: {
          borderWidth: "1px",
          padding: "10px",
          borderRadius: "6px",
          boxSizing: "border-box",
        },
        boxNumber: {
          fontSize: 18,
          textAlign: "right",
          wordWrap: "break-word",
        },
        boxCaption: {
          fontSize: 12,
          whiteSpace: "nowrap",
          paddingLeft: "5px",
          height: "24px",
        },
        tripAssignBoxCaption: {
          fontSize: 12,
          color: "black",
        },
        leftNotices: {
          fontSize: 14,
        },
        searchBar: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        },
        invisible: {
          display: "none",
        },
        oddRow: {
          backgroundColor: "#EEE",
        },
        evenRow: {
          backgroundColor: "#FFF",
        },
    };

    const filterButtonStyle = {
        base: {
            padding: '8px 16px',
            margin: '0 8px',
            borderRadius: '4px',
            border: '2px solid', // Remove the default color here
            cursor: 'pointer',
            fontWeight: 'bold',
            transition: 'all 0.2s ease',
            backgroundColor: 'transparent',
        },
        active: {
            backgroundColor: '#13ACB1',
            color: 'white',
        }
    };
    

    const [tripStats, setTripStates] = useState(props.tripStats);
    const icons = {
        "CalledInToday":RouteIcon,
        "RushToday":RouteIcon,
        "LastHour":BoltIcon,
        "RushLastHour":BoltIcon,
        "PoddedBurnsToday":WatchLaterIcon,
        "BurnsLastHour":WatchLaterIcon,
        "Burning":WatchLaterIcon,
        "DeliveredToday":CheckCircleIcon,
        "DeliveredLastHour":CheckCircleIcon,
        "DCTinNextHour":CheckCircleIcon,
        "DCTRestOfDay":CheckCircleIcon,
        "DCTFutureDates":CheckCircleIcon,
        "DriversLoggedIn":PersonPinIcon,
        "tripAssignStats":AssignmentIndIcon,
        "ServiceAlerts":NotificationsIcon,
        "ExceptionActive":WarningIcon,
        "ExceptionActiveSale":PaidIcon,
        "ExceptionActiveDC":ModeOfTravelIcon

    }

    const titles = {
        "CalledInToday":"TRIPS Today",
        "RushToday":"Rush",
        "LastHour":"Trips Last Hr",
        "RushLastHour":"Rush Last Hr",
        "PoddedBurnsToday":"LATE",
        "BurnsLastHour":"Late Last Hr",
        "Burning":" Late Not Del",
        "DeliveredToday":" PODS",
        "DeliveredLastHour":"PODS Last Hr",
        "DCTinNextHour":"DCT Next Hr",
        "DCTRestOfDay":" DCT TODAY",
        "DCTFutureDates":"DCT FUTURE",
        "DriversLoggedIn":"DRIVERS",
        "tripAssignStats": "ASSIGN",
        "ServiceAlerts":"ALERTS",
        "ExceptionActive":"EXCEPTION",
        "ExceptionActiveSale":"SALES",
        "ExceptionActiveDC":" D/C"
    }

    const boxColors = {
        "CalledInToday":"#F78133",
        "RushToday":"#9585EC",
        "LastHour":"#F78133",
        "RushLastHour":"#9585EC",
        "PoddedBurnsToday":"#DE3562",
        "BurnsLastHour":"#DE3562",
        "Burning":"#DE3562",
        "DeliveredToday":" #13ACB1",
        "DeliveredLastHour":"#13ACB1",
        "DCTinNextHour":"#9585EC",
        "DCTRestOfDay":"#9585EC",
        "DCTFutureDates":"#9585EC",
        "DriversLoggedIn":"#13ACB1",
        "tripAssignStats": "#13ACB1",
        "ServiceAlerts":"#DE3562",
        "ExceptionActive":"#F78133",
        "ExceptionActiveSale":"#F78133",
        "ExceptionActiveDC": "#F78133"
    }

    const boxClass = {
        "CalledInToday":"statBox statBoxWarning",
        "RushToday":"statBox statBoxSecondary",
        "LastHour":"statBox statBoxWarning",
        "RushLastHour":"statBox statBoxSecondary",
        "PoddedBurnsToday":"statBox statBoxDanger",
        "BurnsLastHour":"statBox statBoxDanger",
        "Burning":"statBox statBoxDanger",
        "DeliveredToday":"statBox statBoxPrimary",
        "DeliveredLastHour":"statBox statBoxPrimary",
        "DCTinNextHour":"statBox statBoxSecondary",
        "DCTRestOfDay":"statBox statBoxSecondary",
        "DCTFutureDates":"statBox statBoxSecondary",
        "DriversLoggedIn":"statBox statBoxPrimary",
        "tripAssignStats": "statBox statBoxWarning",
        "ServiceAlerts":"statBox statBoxDanger",
        "ExceptionActive":"statBox statBoxWarning",
        "ExceptionActiveSale":"statBox statBoxWarning",
        "ExceptionActiveDC": "statBox statBoxWarning"
    }
    
    useEffect(() => {
        console.log("===========Dashboard=============" + JSON.stringify(props.tripStats)); 
        setTripStates(props.tripStats) 
    }, [props.tripStats]);

    useEffect(() => {
      setLocalServiceChecked(props.serviceChecked);
    }, [props.serviceChecked]);
  
    useEffect(() => {
        setLocalDispatchChecked(props.dispatchChecked);
    }, [props.dispatchChecked]);

    useEffect(() => {
        setLocalSalesChecked(props.salesChecked);
    }, [props.salesChecked]);

    useEffect(() => {
        setLocalDcChecked(props.dcChecked);
    }, [props.dcChecked]);

    // Effect to set initial checked states based on saved filter
    useEffect(() => {
        const savedFilter = getInitialFilter();
        handleFilterChange(savedFilter);
    }, []);
   
    const boxWidth =  'auto';  
    //const boxWidth =  '120px';   

    const renderTripStatusBox = (key,label, value, BoxIcon)  =>{
       return(
        key !== "companyID" && key !== "tripAssignStats" && key !== "ServiceAlerts" && key !== "ExceptionActive"  && key !== "DriversLoggedIn"?
         renderStatusBox(key,label, value, BoxIcon)     
        :<></>
       )
    }


    const renderStatusBox = (key,label, value, BoxIcon)  =>{
        return(         
        <Box
             style={classes.itemBox}
             minWidth= {boxWidth}
             className={boxClass[key]}
             border={1} 
             // sx={{ border: `1px solid ${boxColors[key]}`,marginLeft:'10px' }}          
             sx={{ border: `1px solid ${boxColors[key]}` }}
         >
            <Box
                 style={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                 }}
             >
                 <div style={{ color: boxColors[key] }}>
                     <BoxIcon></BoxIcon>
                 </div>
                 <div style={{ color: boxColors[key] }}>
                     <Typography color="inherit" style={classes.boxCaption}>
                        {label}
                     </Typography>
                 </div>
             </Box>
             <Box>
                 <Typography color="white" style={classes.boxNumber}>
                    {value}
                 </Typography>
             </Box>
        </Box>         
        )
     }

    const handleFilterChange = (filterType) => {
        setActiveFilter(filterType);
        // Save to localStorage
        localStorage.setItem('dashboardActiveFilter', filterType);
        
        // Update parent component states based on selected filter
        props.setServiceChecked(filterType === 'SERVICE');
        props.setDispatchChecked(filterType === 'DISPATCH');
        
        // If it's ALL, set both to true
        if (filterType === 'ALL') {
            props.setServiceChecked(true);
            props.setDispatchChecked(true);
        }
    };

    const renderFilterButton = (name) => {
        // Assign colors based on the button name
        const buttonColor = name === "SERVICE" 
            ? "#F78133" 
            : name === "DISPATCH" 
            ? "#9585EC"
            : name === "ALL"
            ? "#13ACB1"
            : "#ccc";

        const isActive = activeFilter === name;

        return (
            <button
                onClick={() => handleFilterChange(name)}
                style={{
                    ...filterButtonStyle.base,
                    borderColor: buttonColor,  // Set border color to match the button's color
                    ...(isActive
                        ? { backgroundColor: buttonColor, color: 'white' }
                        : { color: buttonColor }),
                }}
            >
                {name}
            </button>
        );
    };

    return (
        <AppBar position="static" className="appBarDark" style={classes.appBar}>
        <Box display="flex" flexWrap="nowrap" flexDirection="column" m={1}>      
          <Box
            display="flex"
            //flexGrow = {1}
            justifyContent="stretch"
            border={0}
            className="statsWrapper"
            sx={{ height: "auto" }}
            
          >
            {renderStatusBox("tripAssignStats",titles["tripAssignStats"],tripStats["tripAssignStats"], icons["tripAssignStats"])}
            {renderStatusBox("ServiceAlerts",titles["ServiceAlerts"],tripStats["ServiceAlerts"], icons["ServiceAlerts"])}
            {renderStatusBox("ExceptionActive",titles["ExceptionActive"],tripStats["ExceptionActive"], icons["ExceptionActive"])}
            { Object.keys(tripStats).map((key) =>
                renderTripStatusBox(key,titles[key],tripStats[key], icons[key])       
            )}
            {renderStatusBox("DriversLoggedIn",titles["DriversLoggedIn"],tripStats["DriversLoggedIn"], icons["DriversLoggedIn"])}           
          </Box>
          <div style={{ 
            padding: '10px 15px', 
            display: 'flex', 
            alignItems: 'center' 
          }}>
            <Typography 
                style={{ 
                    color: 'white',
                    marginRight: '12px',
                    fontSize: '14px',
                    fontWeight: 'bold'
                }}
            >
                SHOW:
            </Typography>
            {renderFilterButton("ALL")}
            {renderFilterButton("SERVICE")}
            {renderFilterButton("DISPATCH")}
          </div>
        </Box>
      </AppBar>
    );
};

export default DashboardBar