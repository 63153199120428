import React,{useRef} from 'react';
import { useHistory  } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {Toolbar,Tooltip,IconButton} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import AdjustIcon from '@material-ui/icons/Adjust';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/Inbox';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import LogoutIcon from '@mui/icons-material/Logout';
import Link from '@material-ui/core/Link';
import { palette } from './palette';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { useLocation } from 'react-router-dom';
import 'animate.css/animate.css';
import HttpRequest from './HttpService';
import NoticeEmitter from "./NoticeUtil";

import SelectSearch from 'react-select-search';
import "./index.css";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import BetaIcon from '@mui/icons-material/Science';



const navLinks = [
    { path: '/accountList', label: 'Account List' },
    { path: '/web5', label: 'WebX' },
    { path: '/driver', label: 'Driver' },
    { path: '/search', label: 'Search' },
    { path: '/dashBoard', label: 'Dashboard' },
    { path: '/service', label: 'Service' },
    { path: '/messageHistory', label: 'Message History' },
    { 
        label: 'Dev',
        children: [
            { path: '/test', label: 'Test Page' },
            { path: '/driverGoogle', label: 'Driver Google' }
        ]
    }
];

const NavLink = withStyles({
    root: {
        position: 'relative',
        color: props => props.selected ? '#13ACB1' : 'rgba(255,255,255,.75)',
        backgroundColor: props => props.selected ? 'rgba(0,0,0,0.05)' : 'transparent',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        fontWeight: '600',
        fontSize: '14px',
        textTransform: 'uppercase',
        height: '100%',
        cursor: 'pointer',
        '&:hover': {
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.05)',
            textDecoration: 'none',
            '& .submenu': {
                display: 'block'
            }
        }
    },
    submenu: {
        display: 'none',
        position: 'absolute',
        top: '100%',
        right: 0, 
        backgroundColor: '#1a1a1a',
        minWidth: '160px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        zIndex: 1000,
        padding: '4px 0'
    },
    submenuItem: {
        color: 'rgba(255,255,255,.75)',
        padding: '8px 16px',
        fontSize: '13px',
        width: '100%',
        display: 'block',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)',
            color: 'white'
        }
     }
})(({ classes, link, selected, onClick }) => {
    if (link.children) {
        return (
            <div className={classes.root}>
                <span>{link.label}</span>
                <div className={`submenu ${classes.submenu}`}>
                    {link.children.map(child => (
                        <Link
                            key={child.path}
                            href={child.path}
                            className={classes.submenuItem}
                        >
                            {child.label}
                        </Link>
                    ))}
                </div>
            </div>
        );
    }
    return (
        <Link 
            href={link.path} 
            className={classes.root}
            onClick={onClick}
        >
            {link.label}
        </Link>
    );
});


// https://material-ui.com/components/app-bar/#app-bar-with-a-primary-search-field
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    logoButton: {
        marginRight: '15px',
        fontWeight: 900,
        fontSize:'20px',
        textShadow: '0 0 10px rgba(0,0,0,0.4)',
    },
    title: {
        display: 'block',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            alignItems: 'center',
        }
    },
    sectionDesktopLG: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center',
        }
    },
    menuDesktop: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            alignItems: 'stretch',
            alignSelf: 'stretch',
        }
    },
    sectionDesktopXL: {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'flex',
            alignItems:'center',
        }
    },
    controlsContainer: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: '200px minmax(200px, 300px) 200px minmax(240px, 400px) 160px',
            gap: '20px',
            alignItems: 'center',
            flex: 1,
            margin: '0 20px',
            '& > *': {
                minWidth: '0', // Prevents grid item overflow
            }
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    sectionTablet: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    sectionTabletXL: {
        display: 'flex',
        [theme.breakpoints.up('xl')]: {
            display: 'none',
        }
    },
    sectionHiddenXL: {
        display: 'flex',
        [theme.breakpoints.up('xl')]: {
            display: 'none',
        },
    },
    menuPaperWhite: {
        backgroundColor: "White"
    },
    drawerList: {
        width: 400,
    },
    jobIdInput: {
        '& .MuiOutlinedInput-root': {
          color: 'white',
          '& fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.23)',
          },
          '&:hover fieldset': {
            borderColor: 'white',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white',
          },
        },
        '& .MuiInputLabel-root': {
          color: 'rgba(255, 255, 255, 0.7)',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'white',
        },
        '& .MuiInputBase-input': {
          color: 'white',
        },
      },
      accountControls: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        flex: 1,
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%'
        }
    },
    
    webxButtons: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        marginLeft: '10px',
        borderLeft: '1px solid rgba(255,255,255,0.1)',
        paddingLeft: '10px'
    },
    jobIdInput: {
        minWidth: '200px' // Added fixed width
    },
    companySelect: {
        minWidth: '300px' // Added fixed width
    },
    accountInput: {
        minWidth: '200px' // Added fixed width
    },
    accountSelect: {
        minWidth: '400px' // Added fixed width
    },
    
    navigationMenu: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        }
    },
    
    mobileMenu: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    menuItem: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
            '& a': {
                color: 'white !important'
            }
        }
    },
    menuLink: {
        width: '100%',
        padding: '8px 16px',
        color: props => props.selected ? '#13ACB1 !important' : 'rgba(255,255,255,.75)',
        textDecoration: 'none',
        display: 'block',
        textTransform: 'uppercase',
        fontSize: '14px',
        fontWeight: '600'
    },
    menuNestedItem: {
        backgroundColor: 'transparent',
        padding: 0,
        paddingLeft: theme.spacing(2),
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
            '& a': {
                color: 'white !important'
            }
        }
    },
}));

export default function NavigationBar(props) {
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [messagesAnchorEl, setMessagesAnchorEl] = React.useState(false);
    const [jobSearchAnchorEl, setJobSearchAnchorEl] = React.useState(false);
    const [accountAnchorEl, setAccountAnchorEl] = React.useState(false);
    const [jobSearchInput, setJobSearchInput] = React.useState("");
    const [accountInput, setAccountInput] = React.useState("");
    const [companyChoice, setCompanyChoice] = React.useState(props.companyID);
    const [accountChoice, setAccountChoice] = React.useState('');
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const hasMessages = props.messages.length != 0
    const isMessagesOpen = Boolean(messagesAnchorEl) && hasMessages;
    const isJobSearchOpen = Boolean(jobSearchAnchorEl);
    const isAccountOpen = Boolean(accountAnchorEl);

    const [jobIdInput, setJobIdInput] = React.useState("");

    const companyList = props.companyList;
    const accountList = props.accountList;

    const searchInput = useRef();

    const [isHovered, setIsHovered] = React.useState(false);


    //https://stackoverflow.com/questions/59647940/how-can-i-use-ref-in-textfield
    const valueRef = useRef();

    /*
    // const uniqueNames = [...new Map(props.accountList.map(company => (
    //      [company.companyName, [company.companyID, company.companyName]]
    // ))). values()];

    const uniqueNames = [...new Map(props.accountList.map(company => (
        [company.Shipper, [company.account, company.Shipper]]
    ))). values()];
    */


    //still need to implement job search and account prop logic
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
        console.log(isMobileMenuOpen);
    };

    const handleMessagesOpen = (event) => {
        setMessagesAnchorEl(event.currentTarget);
    }

    const handleMessagesClose = () => {
        setMessagesAnchorEl(false);
    }

    const handleJobSearchOpen = (event) => {
        setJobSearchAnchorEl(event.currentTarget);
    }


    const handleJobSearchSubmit = e => {  //////???????????????
        e.preventDefault();
        console.log(jobSearchInput);
        console.log(companyChoice);
        //props.setJobSearch(jobSearchInput);
        //props.setJobSearchChoice(companyChoice);
        setJobSearchInput("");
        handleJobSearchClose();
        if(jobSearchInput.length === 8){
            HttpRequest.getSearchJobID(props.accessToken, jobSearchInput).then(async function(body){
                console.log("getSearchJobID-" + JSON.stringify(body));
                try{
                    if(body.data && body.data[0].ResultCode && body.data[0].ResultCode === -1){
                         alert("can't find the job.");
                    }
                    else if(body.data && body.data[0].jobID){
                        props.setJobSearch(body.data[0].jobID);
                        //window.location.href='/jobDetail/'+ body.data[0].jobID;
                        history.push(`/jobDetail/${body.data[0].jobID}`);
                    }
                }
                catch(e){
                    console.log("getSearchJobID exception:" + e);
                }
            });
        }

    }

    const handleJobSearchClose = () => {
        setJobSearchAnchorEl(false);
    }

    const changeJobSearch = (e) => {
        if(e.target.value.length>=4){
            setJobSearchInput(e.target.value);
            getJobDetail(e.target.value);
        }
    }

    const getJobDetail = (jobID) => {
        HttpRequest.getSearchJobID(props.accessToken, jobID).then(async function(body) {
            console.log("getSearchJobID-" + JSON.stringify(body));
            try {
                if (body.data === null || body.data.length === 0) {
                    alert("Can't find the job.");
                    setJobIdInput(""); // Clear input on error
                } else {
                    if (body.data && body.data[0].ResultCode && body.data[0].ResultCode === -1) {
                        alert("Can't find the job.");
                        setJobIdInput(""); // Clear input on error
                    } else if (body.data && body.data[0].jobID) {
                        props.setJobSearch(body.data[0].jobID);                       
                        HttpRequest.getTripIsLattice(props.accessToken, body.data[0].jobID).then((response) => {  
                            console.log("getTripIsLattice-" + JSON.stringify(response.data));  
                            if (response.data[0].isLattice) {
                                history.push(`/latticeJobDetail/${response.data[0].jobID}`);
                            } else {
                                history.push(`/jobDetail/${response.data[0].jobID}`);
                            }
                            setJobIdInput(""); // Clear input after successful navigation
                        }).catch(error => {
                            console.error("Error in getTripIsLattice:", error);
                            setJobIdInput(""); // Clear input on error
                        });
                    }
                }
            } catch (e) {
                console.log("getSearchJobID exception:" + e);
                setJobIdInput(""); // Clear input on exception
            }
        }).catch(error => {
            console.error("Error in getSearchJobID:", error);
            setJobIdInput(""); // Clear input on request error
        });
    };

    const handleAccountOpen = (event) => {
        setAccountAnchorEl(event.currentTarget);
        console.log(props.accountList);
    }

    const handleAccountSubmit = e => {       
        if(accountChoice !== ""){
            // localStorage.setItem('currentAccount',accountChoice); 
            // window.location.href='/web5'; 
            
            HttpRequest.getAccountWebToken(props.accessToken, accountChoice).then(async function(body){
                console.log("handleAccountSubmit getAccountWebToken-" + JSON.stringify(body.data));
                try{
                    //let webURL =  body.data[0].webAddress;
                    //webURL = webURL.replace('ship','webx');

                    let webURL = body.data[0].entryURL;
                    if(webURL.startsWith("http://"))
                        webURL = webURL.replace("http://", "https://");
                  
                    //console.log("getAccountWebToken webURL-" + webURL);
                    localStorage.setItem('currentAccount',accountChoice);
                    localStorage.setItem('web5URL',webURL);
                    window.location.href='/web5';
                }
                catch(e){
                    console.log("getAccountWebToken exception:" + e);
                }
             });
             
        }
        setAccountChoice("");
        valueRef.current.value="";
        handleAccountClose();
    }

    //handleAccountSubmit getAccountWebToken-[{"web5Token":"1CB40D24-5875-4F1E-8DD3-3A98E328C194","webAddress":"https://ship.directx.ca/#/CreateOrder?sso_token=1CB40D24-5875-4F1E-8DD3-3A98E328C194","entryURL":"http://webx.directx.ca/#/CreateOrder?sso_token=1CB40D24-5875-4F1E-8DD3-3A98E328C194"}]
    const doLatticeAccountSubmit = e => {       
        if(accountChoice !== ""){
            HttpRequest.getAccountWebToken(props.accessToken, accountChoice).then(async function(body){
                console.log("handleAccountSubmit getAccountWebToken-" + JSON.stringify(body.data));
                try{
                    let webURL =  `https://lattice-webx.azurewebsites.net/#/CreateOrder?sso_token=${body.data[0].web5Token}`;
                    console.log("getAccountWebToken webURL-" + webURL);
                    localStorage.setItem('currentAccount',accountChoice);
                    localStorage.setItem('web5URL',webURL);
                    window.location.href='/web5';
                }
                catch(e){
                    console.log("getAccountWebToken exception:" + e);
                }
             });
             
        }
        setAccountChoice("");
        valueRef.current.value="";
        handleAccountClose();
    }

    const doWebxAccountSubmit = e => {       
        if(accountChoice !== ""){
            HttpRequest.getAccountWebToken(props.accessToken, accountChoice).then(async function(body){
                console.log("handleAccountSubmit getAccountWebToken-" + JSON.stringify(body.data));
                try{
                    let webURL =  `https://webx.directx.ca/#/CreateOrder?sso_token=${body.data[0].web5Token}`;
                    console.log("getAccountWebToken webURL-" + webURL);
                    localStorage.setItem('currentAccount',accountChoice);
                    localStorage.setItem('web5URL',webURL);
                    window.location.href='/web5';
                }
                catch(e){
                    console.log("getAccountWebToken exception:" + e);
                }
             });
             
        }
        setAccountChoice("");
        valueRef.current.value="";
        handleAccountClose();
    }

    const doBetaLatticeAccountSubmit = e => {       
        if(accountChoice !== ""){
            HttpRequest.getAccountWebToken(props.accessToken, accountChoice).then(async function(body){
                console.log("handleAccountSubmit getAccountWebToken-" + JSON.stringify(body.data));
                try{
                    let webURL = `https://lattice-webx-stage.azurewebsites.net/#/CreateOrder?sso_token=${body.data[0].web5Token}`;
                    console.log("getAccountWebToken webURL-" + webURL);
                    localStorage.setItem('currentAccount',accountChoice);
                    localStorage.setItem('web5URL',webURL);
                    window.location.href='/web5';
                }
                catch(e){
                    console.log("getAccountWebToken exception:" + e);
                }
             });
        }
        setAccountChoice("");
        valueRef.current.value="";
        handleAccountClose();
    }

    const changeAccount = e => {
        console.log("changeAccount- " + e.target.value);
        if(e.target.value.length >= 4 ){
           setAccountInput(e.target.value);
           setAccountChoice(e.target.value);
       }
    }

    
    const changeAccountSelect_mobile = e => {
        console.log("changeAccountSelect-" + e.target.value);
        if(e.target.value){
           setAccountChoice(e.target.value);
           setAccountInput("");
           valueRef.current.value="";
        }
    }
    

    const changeAccountSelect = (...args) => {
        // searchInput.current.querySelector("input").value = "";
        searchInput.current.value ="";
       // console.log("ARGS:", args);
        setAccountChoice(args[0]);
        setAccountInput("");
        valueRef.current.value="";
    };

    const handleAccountClose = () => {
        setAccountAnchorEl(false);
    }

    const handleFilter = (items) => {
        return (searchValue) => {
          if (searchValue.length === 0) {
            return accountList;
          }
          var updatedItems = items.filter((item) => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase());
          });
          return updatedItems;
        };
    };


    const pathname = useLocation().pathname;

    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';

    const renderMobileMenu = (
    <Menu
        anchorEl={mobileMoreAnchorEl || isHovered ? document.querySelector('.menu-trigger') : null}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(mobileMoreAnchorEl) || isHovered}
        onClose={() => {
            setMobileMoreAnchorEl(null);
            setIsHovered(false);
        }}
        MenuListProps={{
            onMouseEnter: () => setIsHovered(true),
            onMouseLeave: () => setIsHovered(false)
        }}
        PaperProps={{
            style: {
                backgroundColor: '#1a1a1a'
            }
        }}
    >
        {props.isLoggedIn && navLinks.map(link => {
            if (link.children) {
                return link.children.map(child => (
                    <MenuItem key={child.path} className={classes.menuNestedItem}>
                        <Link 
                            href={child.path}
                            className={classes.menuLink}
                            selected={pathname === child.path}
                        >
                            {child.label}
                        </Link>
                    </MenuItem>
                ));
            }
            return (
                <MenuItem key={link.path} className={classes.menuItem}>
                    <Link 
                        href={link.path}
                        className={classes.menuLink}
                        selected={pathname === link.path}
                    >
                        {link.label}
                    </Link>
                </MenuItem>
            );
        })}
        {!props.isLoggedIn && (
            <MenuItem className={classes.menuItem}>
                <Link 
                    href="/"
                    className={classes.menuLink}
                >
                    Login
                </Link>
            </MenuItem>
        )}
    </Menu>
);

  const renderMessages = (
    <Drawer
    anchor = 'right'
    open = {isMessagesOpen}
    onClose = {handleMessagesClose}>
        <div className = {classes.drawerList}>
        <List>
            {props.messages.map(message => (
                <div>
                <ListItem button key = {message}>
                    <ListItemIcon>
                        <AdjustIcon fontSize='small' color='primary'/>
                    </ListItemIcon>
                    <ListItemText primary = {message}/>
                </ListItem>
                <Divider/>
                </div>
            ))}
        </List>
        </div>
    </Drawer>



    /*<Menu dense
    anchorEl={messagesAnchorEl}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    keepMounted
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={isMessagesOpen}
    onClose={handleMessagesClose}
    classes = {{paper: classes.menuPaperWhite}}
    >
        {props.messages.map(message => (
            <MenuItem disablePadding>
                <ListItemIcon>
                    <AdjustIcon fontSize='small' style = {{fill: "red"}}/>
                </ListItemIcon>
                <ListItemText
                disableTypography
                primary={message}/>
            </MenuItem>
        ))}
    </Menu>*/
  );

  const renderJobSearch = (
    <Menu
        anchorEl={jobSearchAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isJobSearchOpen}
        onClose={handleJobSearchClose}
        classes = {{paper: classes.menuPaperWhite}}
    >
        <MenuItem className={'whiteDropDownItem'}>
            <Grid>
                <Grid item xs = {12}>
                <FormControl fullWidth>
                        <TextField variant = "outlined"
                        size = "small"
                        label = "Job ID"
                        // type="number"
                        // onChange = {changeJobSearch}
                        onBlur={changeJobSearch}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs = {12}>
                    <FormControl fullWidth margin = 'normal'>
                        <TextField variant = "outlined"
                        select
                        size = "small"
                        label = "Company"
                        value = {companyChoice}
                        onChange = {event => {
                            console.log('company is ' + event.target.value);
                            setCompanyChoice(event.target.value);
                            props.setCompanyID(event.target.value);
                        }}>
                            {companyList.map(company => (
                                <MenuItem key = {company.companyID} value = {company.companyID}>{company.CompanyName}</MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs = {12}>
                    <Button variant = "contained"
                            color="primary"
                            style={{width:"100%", display:'flex', justifyContent:'space-between'}}
                            endIcon={<SearchIcon style={{verticalAlign: 'middle', transform: 'scale(1)'}}/>}
                            onClick = {handleJobSearchSubmit}>
                                Search
                    </Button>
                </Grid>
            </Grid>
        </MenuItem>
    </Menu>
  );

  const renderAccount = (
    <Menu dense
    anchorEl={accountAnchorEl}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    keepMounted
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={isAccountOpen}
    onClose={handleAccountClose}
    classes = {{paper: classes.menuPaperWhite}}
    >
        <MenuItem className={'whiteDropDownItem'}>
            <Grid>
                <Grid item xs = {12}>
                    <FormControl fullWidth>
                        <TextField variant = "outlined"
                        size = "small"
                        label = "Account #"
                        inputRef={valueRef}
                        onChange = {changeAccount}
                        inputProps={{ maxLength: 10 }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs = {12}>
                    <FormControl fullWidth margin = 'normal'>
                        <TextField                          
                            value={accountChoice}
                            onChange= {changeAccountSelect_mobile}
                            variant="outlined"
                            label="Choose Account"
                            select
                        >
                             {accountList.map(account => (
                                <MenuItem key = {account.account} value = {account.account}>{account.Shipper} - {account.account}</MenuItem>
                            ))}
                        </TextField> 
                    </FormControl>
                </Grid>
                <Grid item xs = {12}>
                    <Button variant = "contained"
                            color="primary"
                            style={{width:"100%", display:'flex', justifyContent:'space-between'}}
                            endIcon={<AccountCircle style={{verticalAlign: 'middle', transform: 'scale(1)'}}/>}
                            onClick = {handleAccountSubmit}>
                            Switch Account
                    </Button>                   
                </Grid>
            </Grid>
        </MenuItem>
    </Menu>
  );
    //https://material-ui.com/components/drawers/#swipeable
    const renderMenuDrawer = ( <div/>
    // <Drawer anchor='top' open={drawerOpen} onClose={setDrawerOpen(false)}>
    //     <div
    //     style={{
    //         anchor: 'top',
    //         width: 'auto',
    //     }}
    //     role="presentation"
    //     onClick={setDrawerOpen(false)}
    //     onKeyDown={setDrawerOpen(false)}
    //     >
    //         <List>
    //             <ListItem button key="test">
    //                 <Typography variant="body1">Test</Typography>
    //             </ListItem>
    //         </List>
    //     </div>
    // </Drawer>
    );

    const userUpdate =(companyID) =>{
        HttpRequest.userUpdate(props.accessToken, localStorage.getItem('currentAccount'), companyID, null).then(async function(body){
            console.log(companyID + "-----------userUpdate-" + JSON.stringify(body));
            localStorage.setItem('companyID',companyID.toString());
            NoticeEmitter.emit('currentCompanyID', companyID);
        });
    }

    return (
        <div id="mainHeader" className={classes.grow}>
            <AppBar position="sticky" elevation={0}>
                <Toolbar style={{padding: '0 15px'}}>
                    <Typography className={classes.logoButton} color="white" align="left">InX</Typography>
                    
                    {props.isLoggedIn && (
                        <div className={classes.controlsContainer}>
                            <TextField
                                variant="outlined"
                                size="small"
                                label="Job ID"
                                value={jobIdInput}
                                onChange={(e) => setJobIdInput(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        getJobDetail(e.target.value);
                                    }
                                }}
                                className={classes.jobIdInput}
                                fullWidth
                            />
                            
                            <TextField 
                                variant="outlined"
                                select
                                size="small"
                                label="Company"
                                value={companyChoice}
                                onChange={event => {
                                    setCompanyChoice(event.target.value);
                                    props.setCompanyID(event.target.value);
                                    userUpdate(event.target.value);
                                }}
                                fullWidth
                            >
                                {companyList.map(company => (
                                    <MenuItem key={company.companyID} value={company.companyID}>
                                        {company.CompanyName}
                                    </MenuItem>
                                ))}
                            </TextField>
    
                            <TextField 
                                variant="outlined" 
                                size="small"  
                                label="Account #"  
                                inputRef={valueRef}  
                                onChange={changeAccount} 
                                inputProps={{ maxLength: 10 }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAccountSubmit();
                                    }
                                }}
                                fullWidth
                            />
    
                            <FormControl fullWidth>
                                <SelectSearch
                                    ref={searchInput}
                                    options={accountList}
                                    filterOptions={handleFilter}
                                    value={accountChoice}
                                    name="account" 
                                    placeholder="Choose Account"
                                    search
                                    onChange={changeAccountSelect}
                                />
                                <ArrowDropDownIcon style={{position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)'}}/>
                            </FormControl>
    
                            <div className={classes.webxButtons}>
                                <Tooltip title="Lattice Webx" placement="top" arrow>
                                    <IconButton onClick={doLatticeAccountSubmit}><FiberNewIcon/></IconButton>
                                </Tooltip>
                                <Tooltip title="Beta Lattice" placement="top" arrow>
                                    <IconButton onClick={doBetaLatticeAccountSubmit}><BetaIcon/></IconButton>
                                </Tooltip>
                                <Tooltip title="Legacy Webx" placement="top" arrow>
                                    <IconButton onClick={doWebxAccountSubmit}><WorkHistoryIcon/></IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                  
                    <div className={classes.grow} />
    
                    {props.isLoggedIn && (
                        <>
                            <div className={classes.menuDesktop}>
                                {navLinks.map((link) => (
                                    <NavLink 
                                        key={link.path || link.label} 
                                        link={link}
                                        selected={pathname === link.path}
                                    />
                                ))}
                                <NavLink 
                                    link={{ path: "/", label: <LogoutIcon fontSize='small' color='white'/> }}
                                    onClick={props.handlerLogout}
                                />
                            </div>
    
                            <div className={classes.sectionMobile}>
                                <IconButton
                                    className="menu-trigger"
                                    aria-controls={mobileMenuId}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    <MoreIcon />
                                </IconButton>
                            </div>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            {renderMessages}
            {renderMobileMenu}
            {renderJobSearch}
            {renderAccount}
        </div>
    );
}


//https://stackoverflow.com/questions/59647940/how-can-i-use-ref-in-textfield

//for account list
//https://mui.com/material-ui/react-autocomplete/
