import React, { useRef, useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.dark.css";
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Paging,
  Export,
  ColumnChooser,
  Pager,
  SearchPanel,
  GroupPanel,
  Grouping,
  GroupItem,
  SortByGroupSummaryInfo,
  Summary,
  MasterDetail,
  StateStoring,
  ColumnFixing,
  HeaderFilter,
  FilterPanel,
  FilterBuilderPopup,
  Scrolling,
} from "devextreme-react/data-grid";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import NoticeEmitter from "../NoticeUtil.js";
import httpRequest from "../HttpService";

import "./search.css";
import MasterDetailView from "./MaterDetailView";

export default function DEDataGrid(props) {
  const [trips, setTrips] = useState([]);
  const orderDataTable = useRef(null);

  const useStyles = makeStyles({
    underline: {
      "&&&:before": {
        borderBottom: "none"
      },
      "&&:after": {
        borderBottom: "none"
      }
    }
  });

  const classes = useStyles();

  NoticeEmitter.addListener("resetTable", (data) => {
    console.log("==========NoticeEmitter resetTable===============");
    try {
      if(orderDataTable !== null && orderDataTable.current!== null && orderDataTable.current.instance !== undefined && orderDataTable.current.instance !== null){
         orderDataTable.current.instance.clearSelection();        
      }
    } catch (e) {
      console.log(e);
    }
  });

  const allowedPageSizes = [30, 50, 100];

  useEffect(() => {
      console.log("stateReset-" + props.stateReset);   
      orderDataTable.current.instance.state(null);   
      props.setStateReset(false); 
  }, [props.stateReset]);

  useEffect(() => {
    console.log("Raw trips data:", props.trips);
    console.log("search trip length-" + props.trips.length);
    console.log("Sample trip:", props.trips[0]);
    
    const listWithID = props.trips.map((trip, index) => ({
      ...trip,
      id: index,
      SER_TIME: trip.SER_TIME?.toString(),
      RATE: trip.RATE?.toString(),
      dtDCT_UTC: trip.dtDCT_UTC ? format(parseISO(trip.dtDCT_UTC), dateFormat) : null,
      dtCallIn_UTC: trip.dtCallIn_UTC ? format(parseISO(trip.dtCallIn_UTC), dateFormat) : null,
      dtDelivered_UTC: trip.dtDelivered_UTC ? format(parseISO(trip.dtDelivered_UTC), dateFormat) : null,
    }));
    
    console.log("Processed trips:", listWithID);
    setTrips(listWithID);
  }, [props.trips]);

  const dateFormat = "MM/dd/yyyy HH:mm";

  const renderJobID = (params) => {   
    return (
      <a
        style={{ color: "#13ACB1" }}
        onClick={() => {
          let inxURL = params.data.isLattice ? `/latticeJobDetail/${params.value}` : `/jobdetail/${params.value}`;
          window.open(inxURL, "_blank");
        }}
      >
        {params.value}{" "}
      </a>
    );
  };

  const renderInstructions = (params) => {
    return (
      <TextField
        fullWidth      
        multiline 
        placeholder="" 
        style={{ width: '100%' }} 
        defaultValue={params.value} 
        InputProps={{ classes, readOnly: true }}
      />    
    );
  };

  const renderTagIcons = (cellData) => {
    console.log("Rendering tag icons for:", cellData);
    
    let tagArray = [];
    
    if (cellData.value) {
      try {
        tagArray = JSON.parse(cellData.value);
      } catch (error) {
        console.error("Error parsing tag data:", error);
        return <span>Error parsing tags</span>;
      }
    }
  
    // If the array is empty or doesn't exist, return "No tags"
    if (!Array.isArray(tagArray) || tagArray.length === 0) {
      return <span>No tags</span>;
    }
  
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
        {tagArray.map((tag, index) => (
          tag.icon_url && (
            <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
              <img 
                src={tag.icon_url} 
                alt={tag.textKey || `Tag ${index + 1}`} 
                title={`${tag.textKey}${tag.variableDisplayValue ? ': ' + tag.variableDisplayValue : ''}`}
                style={{ width: '24px', height: '24px' }}
                onError={(e) => {
                  console.error(`Failed to load icon: ${tag.icon_url}`);
                  e.target.style.display = 'none';
                }}
              />
            </div>
          )
        ))}
      </div>
    );
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    console.log("============onSelectionChanged --" + JSON.stringify(selectedRowsData));
    props.setSelection(selectedRowsData);
  };

  const onExporting = (e) => {
    console.log("onExporting-" + e.format);

    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Companies");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.xlsx"
          );
        });
      });
      e.cancel = true;
    } else if (e.format === "csv") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Employees");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
      }).then(function () {
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.csv"
          );
        });
      });
    }
  };

  const exportFormats = ["xlsx", "csv"];

  const filterBuilderPopupPosition = {
    of: window,
    at: "top",
    my: "top",
    offset: { y: 10 },
  };

  return (
    <DataGrid
      ref={orderDataTable}
      dataSource={trips}
      showBorders={true} 
      allowColumnReordering={true}
      allowColumnResizing={true}    
      columnResizingMode="widget"
      keyExpr="id" 
      onSelectionChanged={onSelectionChanged}
      onExporting={onExporting}
      onRowPrepared={(e) => {
        if (e.data &&(e.data.Status === "Verified" || e.data.Status === "Invoiced" || e.data.Status === "Delivered"))
          e.rowElement.style.backgroundColor = "#0D191E";
         else if (e.data && e.rowIndex % 2 === 0)
          e.rowElement.style.backgroundColor = "#0D191E";
        else if (e.data && e.rowIndex % 2 !== 0)
          e.rowElement.style.backgroundColor = "#010C11"; 
      }}
    >
      <FilterPanel visible={true} />
      <FilterBuilderPopup position={filterBuilderPopupPosition} />
      <HeaderFilter visible={true} />
      <GroupPanel visible={true} />
      <Grouping autoExpandAll={false} />
      <SearchPanel visible={true} width={240} placeholder="Search..." />
      <Selection
        mode="multiple"
        selectAllMode="page"
        showCheckBoxesMode="always"
      />
      <FilterRow visible={true} />
      <Paging defaultPageSize={50} />
      <Pager
        visible={true}
        showInfo={true}
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
        infoText="Page {0} of {1} ({2} items)"
        displayMode="adaptive"        
      />  
      <Column dataField="jobID" caption="JobID" width={100} cellRender={renderJobID} />
      <Column dataField="ACCOUNT" caption="Account" width={90} />     
      <Column dataField="statusString" caption="Status" width={150} />
      <Column dataField="locationString" caption="Location" width={100} />
      <Column dataField="SER_AREA" caption="Ser. Area" width={80} />
      <Column dataField="SER_TIME" caption="Ser. Time" width={80}/>     
      <Column dataField="rate" caption="Rate" width={70} />  
      <Column dataField="CALLER" caption="Caller" width={100} />       
      <Column dataField="CUST_REF" caption="Reference" width={130}/> 
      <Column dataField="PROOF_DEL" caption="Deli.Proof" width={120}  visible={!props.defaultChecked}/>
      <Column dataField="dtDelivered_UTC" caption="Deli. By" dataType="datetime" width={150}  visible={!props.defaultChecked}/>   
      <Column dataField="pickDriver" caption="1st Drv." width={80} />
      <Column dataField="last_driv" caption="Last Drv." width={80} />  
      <Column dataField="dtCallIn_UTC" caption="Call Time" width={150} dataType="datetime"/>     
      <Column dataField="dtDCT_UTC" caption="Due By"  width={150} dataType="datetime"/>   
      <Column dataField="sCompanyName" caption="From" width={200} />     
      <Column dataField="sAddress" caption="Sh. Address" width={120}/>    
      <Column dataField="sQuad" caption="sh. Quad" width={80}/>
      <Column dataField="sCity" caption="sh. City" width={80} /> 
      <Column dataField="sContact" caption="Sh. Contact" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="sPhone" caption="sh. Phone" width={120} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="sLat" caption="sh. Lat" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="sLong" caption="sh. Long" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cCompanyName" caption="To" width={200}/>
      <Column dataField="cAddress" caption="Co. Address" width={120}/>
      <Column dataField="cQuad" caption="Co. Quad" width={80}/>
      <Column dataField="cCity" caption="Co. City" width={80}/>
      <Column dataField="cContact" caption="Co. Contact" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cPhone" caption="Co. Phone" width={120} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cLat" caption="Co. Lat" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cLong" caption="Co. Long" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="creator" caption="Creator" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="DESC" caption="PackageType" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>  
      <Column dataField="PIECES" caption="Pieces" width={75} visible={props.defaultChecked}/>    
      <Column dataField="WEIGHT" caption="Weight" width={80} visible={props.defaultChecked}/>
      <Column dataField="DEL_INST" caption="Instructions" width={200} cellRender={renderInstructions} visible={props.defaultChecked} />   
      <Column 
        dataField="tagIcons" 
        caption="Tags" 
        width={120}
        cellRender={renderTagIcons}
        allowFiltering={false}
        allowSorting={false}
        visible={true}
      />
      <Column 
        dataField="BOLURL" 
        caption="BOL" 
        width={70} 
        alignment="center"
        cellRender={(cellData) => {
          if (!cellData.value) return null;
          return (
            <a 
              href={cellData.value}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.open(cellData.value, '_blank');
              }}
              style={{ color: "#13ACB1" }}
            >
              <i className="dx-icon-download" />
            </a>
          );
        }}
      />
      <ColumnChooser enabled={true} mode="select" />
      <StateStoring enabled={true} type="localStorage" storageKey="searchStorage" /> 
      <Summary>
        <GroupItem
          column="jobID"
          summaryType="count"
          displayFormat="{0} orders"
        />
        <GroupItem
          column="RATE"
          summaryType="avg"
          valueFormat="decimal"
          displayFormat="Avg Rate:${0}"
          showInGroupFooter={true}
          alignByColumn={false}
        />
        <GroupItem
          column="RATE"
          summaryType="sum"
          valueFormat="##0.00"
          displayFormat="Sum Rate:${0}"
          alignByColumn={true}
        />
      </Summary>
      <SortByGroupSummaryInfo summaryItem="jobID" />
      <MasterDetail enabled={true} component={MasterDetailView} />
      <Export
        enabled={true}
        formats={exportFormats}
        allowExportSelectedData={true}
      />
    </DataGrid>
  );
}